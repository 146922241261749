import Layout from '../layout/index.vue'

export default [
	{
		path: '/good_list',
		name: 'GoodList',
		component: Layout,
		redirect: 'good_list/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/good_list/index.vue')
			},
			{
				path: 'compete',
				component: () => import('../views/good_list/compete.vue'),
			},
			{
				path: 'freezer',
				component: () => import('../views/good_list/freezer.vue'),
			},
			{
				path: 'grant',
				component: () => import('../views/good_list/grant.vue'),
			},
			{
				path: 'release',
				component: () => import('../views/good_list/release.vue'),
			},
			{
				path: 'report',
				component: () => import('../views/good_list/report.vue'),
			},
			{
				path: 'examine',
				component: () => import('../views/good_list/examine.vue'),
			},
			{
				path: 'cate', // 商品分类管理
				component: () => import('../views/good_list/cate.vue'),
			},
			{
				path: 'orderGoodsList', //订单商品
				component: () => import('../views/good_list/orderGoodsList.vue'),
			},{
				path: 'weightUnit', //重量单位
				component: () => import('../views/good_list/weightUnit.vue'),
			},
			{
				path: 'goodsPackage', //商品套餐
				component: () => import('../views/good_list/goodsPackage.vue'),
			},
			{
				path: 'goodsPackageGrade', //等级
				component: () => import('../views/good_list/goodsPackageGrade.vue'),
			},
			
			
		]
	}, {
		path: '/materiel',
		name: 'Materiel',
		component: Layout,
		redirect: 'materiel/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/materiel/index.vue')
			}
		]
	},{
		path: '/brand',
		name: 'Brand',
		component: Layout,
		redirect: 'brand/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/brand/index.vue')
			}
		]
	},
	
];