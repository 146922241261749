<template>
    <div class="layout_main">
        <a-layout id="components-layout-demo-custom-trigger" style="height:100%;">
            <a-layout-header style="background: #fff; padding: 0">
                <div style="display:flex">
                    <div class="logo" :class="[collapsed ? 'not' : '']" ></div>
                    <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="()=> collapsed = !collapsed"/>
                    <div class="flex">
                        <a-menu mode="horizontal" :selectedKeys="top_menu_select_keys" :style="{ lineHeight: '62px'}">
                            <a-menu-item @click="handle_top_menu(item,index)" v-for="(item,index) in menus" :key="item.id">
                                <span>{{item.title}}</span>
                            </a-menu-item>
                        </a-menu>
                    </div>
                    <a-menu mode="horizontal" :defaultSelectedKeys="['2']" :style="{ lineHeight: '62px'}">
                        <a-sub-menu key="sub3">
                            <span slot="title">
                                <span>{{user.username}} ({{user.phone}})</span>
                            </span>
                            <!-- <a-menu-item key="9">基本资料</a-menu-item>
                            <a-menu-item key="10">安全设置</a-menu-item>
                            <a-menu-item key="11">压缩发布</a-menu-item>
                            <a-menu-item key="12">清理缓存</a-menu-item> -->
                        </a-sub-menu>
                        <a-menu-item key="login_exit" @click="login_exit">
                            <a-icon type="menu-unfold" />
                            <span>退出</span>
                        </a-menu-item>
                    </a-menu>
                </div>
            </a-layout-header>
            <a-layout>
                <a-layout-sider v-if="left_nav.length > 0" style="background:#fff; height:100%; overflow:hidden" :trigger="null" collapsible v-model="collapsed">
                    <a-menu mode="inline" class="nav_left" style="overflow-y:auto;overflow-x:hidden; height:100%" :openKeys="left_menu_open_keys" :selectedKeys="left_menu_select_keys" @openChange="handle_left_nav_sub_click">
                        <a-sub-menu v-for="item in left_nav" :selectedKeys="left_menu_select_keys" :key="item.id">
                            <span slot="title">
                                <span class="icon iconfont" :class="[item.icon ? item.icon : 'iconrenshiguanli']"></span>
                                <span>{{item.title}}</span>
                            </span>
                            <a-menu-item v-for="vo in item.sub" :key="vo.id" @click="handle_left_nav_click(vo)">
                                <span class="nav_item">{{vo.title}}</span>
                            </a-menu-item>
                        </a-sub-menu>
                    </a-menu>
                </a-layout-sider>
                <a-layout-content class="layout_content">
					<keep-alive :include="$store.state.keep_live_route">
						<router-view></router-view>
					</keep-alive>
                </a-layout-content>
            </a-layout>
        </a-layout>
		<a-modal width='600px' height='380px' v-model:visible="visible" @ok="handleOk">
			<slot name="title">
				<div class='title_modal'>
					<img src="../assets/img/title_icon.png" class='title_icon' />
					<span>登录密码为初始密码，请更改密码保障安全</span>
				</div>
			</slot>
			<a-form ref="formRef" :form="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-item ref="old_password" required label="原始密码" name="old_password">
					<a-input placeholder='请填写原始密码' v-decorator="[
					  'old_password',
					  {
					    rules: [
					      {
					        required: true,
					        message: '请填写原始密码!',
					      },
					      
					    ],
					  },
					]" />
				</a-form-item>
				<a-form-item ref="new_password" required label="修改密码" name="new_password" has-feedback>
					<a-input placeholder='请输入8-20个字符，包含大小写' v-decorator="[
			          'new_password',
			          {
			            rules: [
			              {
			                required: true,
			                message: '密码格式错误!',
			              },
			              {
			                validator: validateToNextPassword,
			              },
			            ],
			          },
			        ]" type="password" />
				</a-form-item>
		
				<a-form-item ref="new_password_two" required label="确认密码" name="new_password_two" has-feedback>
					<a-input placeholder='再次确认新密码' @blur="handleConfirmBlur" v-decorator="[
				       'new_password_two',
				       {
				         rules: [
				           {
				             required: true,
				             message: '再次确认新密码!',
				           },
				           {
				             validator: compareToFirstPassword,
				           },
				         ],
				       },
				     ]" type="password" />
				</a-form-item>
			</a-form>
			<div class='bg_modal'>
		
			</div>
		</a-modal>
    </div>
</template>

<script>
import { layout_nav,rtLoginOut } from '@/api/api'
import {
		changePassword
	} from '@/api/user'
import method from '../methods/method'
import { Modal } from 'ant-design-vue'

export default {
    components: {

    },
    data() {
        return {
            collapsed: false,
            menus: [],
            left_nav: [],
            top_menu_select_keys: [],
            left_menu_select_keys: [],
            left_menu_open_keys: [],
            user: {},
			confirmDirty: false,
			labelCol: {
				span: 4
			},
			wrapperCol: {
				span: 20
			},
			formState:{},
			visible: false,
        }
    },
    async created() {
        await this.get_nav();
		this.formState = this.$form.createForm(this, {
			name: 'formState'
		});
		
    },
    methods: {
		handleConfirmBlur(e) {
			const value = e.target.value;
			this.confirmDirty = this.confirmDirty || !!value;
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.formState;
			if(value=='Rt888888'){
				callback('新密码不可于初始密码一致');
			}
			let strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
			if (value&&strongPasswordRegex.test(value)) {
				callback();
			} else {
			  callback('密码格式错误');
			}
			
			if (value && value !== form.getFieldValue('new_password')) {
				callback('密码格式错误');
			} else {
				callback();
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.formState;
			if (value && this.confirmDirty) {
				form.validateFields(['new_password_two'], {
					force: true
				});
			}
			callback();
		},
		handleOk() {
			this.formState.validateFields(async (err, values) => {
				if (!err) {
					values.username = this.username
					let data = await changePassword(values);
					if(data.code==1){
						message.success(data.info);
					this.visible = false
					this.formState.resetFields();
					}
					if(data.code==0){
						message.error(data.info);
					}
				}
				
			});
		
		},
        async get_nav() {
            try {
                let data = await layout_nav();
                let menus = data.data.menus;
                this.user = data.data.user
                this.menus = menus;
				this.$store.commit('set', {
					user_info:data.data.user.username
				})
                let route = this.$route;
                let path = route.path;
                if (route.meta.select_path) {
                    path = '/' + route.meta.select_path;
                }
                let select_nav = false;
                if (path != '/') {
                    select_nav = this.lookup_nav_select_keys(menus, path)
                }
                if (select_nav == false) {
                    let indexs = 0
                    for (let i = 0; i < menus.length; i++) {
                        if(menus[i].url.indexOf(path) > -1){
                            indexs = i
                        }
                    }
                    this.top_menu_select_keys = [menus[indexs].id];
                    this.left_nav = menus[indexs].sub ? menus[indexs].sub : '';
                    if(this.left_nav != ''){
                        this.left_menu_select_keys = [menus[indexs].sub[0].sub[0].id];
                        this.left_menu_open_keys = [menus[indexs].sub[0].id];
                    }
                } else {
                    this.left_nav = menus[select_nav.top_nav_index].sub;
                    this.top_menu_select_keys = [select_nav.top_id];
                    this.left_menu_select_keys = [select_nav.id];
                    this.left_menu_open_keys = [select_nav.pid];
                }
            } catch (e) {
                
            }
        },
        lookup_nav_select_keys(menus, path) {
            for (let i = 0; i < menus.length; i++) {
                if (menus[i].url) {
                    let url = menus[i].url.replace('/admin/', '').replace('/crm/', '').replace('.html', '');
                    if (Array.isArray(menus[i].sub) && menus[i].sub.length) {
                        let item = this.lookup_nav_select_keys(menus[i].sub, path);
                        if (item != false) {
                            item.top_id = menus[i].id;
                            item.top_nav_index = i;
                            return item;
                        }
                    }
                    if (('/' + url) == path) {
                        return menus[i];
                    }
                }
            }
            return false;
        },
        handle_top_menu(item, index) {
            this.top_menu_select_keys = [item.id];
            if(item.url.indexOf('http') > -1){
                let token = method.get_local('token');
                let newUrl = item.url.replace('/http', 'http')
                newUrl += item.url.indexOf('?') > -1 ? '&' : '?'
                newUrl += 'rttoken=' + token
                if(item.target == '_blank'){
                    window.open(newUrl,item.target)
                }else{
                    location.href = newUrl
                }
            }else{
                if(item.url != '#' && item.url != '/#'){
                    let pathname = window.location.pathname
                    if(item.url.indexOf(pathname) < 0){
                        this.$router.push({path:item.url});
                        this.left_nav = [];
                    }
                }else{
                    this.left_nav = this.menus[index].sub;
                    this.left_menu_open_keys = [this.menus[index].sub[0].id]
                    this.left_menu_select_keys = [this.menus[index].sub[0].sub[0].id];
                    if(this.left_nav[0].url != '#' && this.left_nav[0].url != '/#'){
                        this.go_url(this.left_nav[0])
                    }else{
                        this.go_url(this.left_nav[0].sub[0])
                    }
                }
            }
        },
        handle_left_nav_click(item) {
            this.left_menu_select_keys = [item.id];
            this.go_url(item)
        },
        handle_left_nav_sub_click(item) {
            this.left_menu_open_keys = item.length ? [item[item.length - 1]] : [];
        },
        go_url(item) {
            if (item.url) {
                let url = item.url.replace('/admin/', '').replace('/crm/', '').replace('.html', '');
                if(url.indexOf('http') > -1){
                    let token = method.get_local('token');
                    let newUrl = url.replace('/http', 'http')
                    newUrl += url.indexOf('?') > -1 ? '&' : '?'
                    newUrl += 'rttoken=' + token
                    if(item.target == '_blank'){
                        window.open(newUrl,item.target)
                    }else{
                        location.href = newUrl
                    }
                }else{
                    this.$router.push('/' + url);
                }
            } else {
                console.error('url不存在')
            }
        },
        login_exit() {
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要退出当前操作系统吗？',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    rtLoginOut().then(res=>{
                        that.$router.replace('/login');
                    })
                }
            });
        },
    }
}
</script>


<style lang="less" scoped>
.layout_main {
    height: 100%;
    .nav_item {
        font-size: 13px;
    }
}
.nav_left{
	.icon{
		margin-right: 10px;
	}
}
/deep/.ant-modal-body {
		position: relative;

	}

	.bg_modal {
		position: absolute;
		width: 208px;
		height: 201px;
		right: 0px;
		bottom: 0px;
		background: url(../assets/img/bg_modal.png) no-repeat;
		background-size: 100%;

	}

	.title_modal {
		margin: 0;
		color: rgba(0, 0, 0, 0.85);
		font-weight: 500;
		font-size: 16px;
		// line-height: 22px;
		word-wrap: break-word;
		display: flex;
		align-items: center;
		margin-right: 30px;
		height: 42px;
		background: #ffffff;
		border-radius: 6px;
		box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.03);
		margin-bottom: 16px;
		// border-bottom: 1px solid rgba(0, 0, 0, 0.06);

	}

	.title_icon {
		width: 24px;
		height: 24px;
		margin-right: 16px;
	}
</style>