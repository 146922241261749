import Layout from '../layout/index.vue'

export default [
	{
		path: '/attendance',
		name: 'Attendance',
		component: Layout,
		redirect: 'attendance/config',
		children: [
			
			{
				path: 'config',
				component: () => import('../views/attendance/config.vue'),
			
				
			},
			{
				path: 'index_config',
				meta: {
					select_path: 'attendance/index'
				},
				component: () => import('../views/attendance/index/config.vue')
			},
			

		]
	},
	{
		path: '/attendance_data',
		name: 'AttendanceData',
		component: Layout,
		redirect: 'attendance_data/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/attendance_data/index.vue')
			}
		]
	},
	{
		path: '/business',
		name: 'Business',
		component: Layout,
		redirect: 'business/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/business/index.vue')
			}
		]
	},
	{
		path: '/notice',
		name: 'Notice',
		component: Layout,
		redirect: 'notice/list',
		children: [
			{
				path: 'list',
				component: () => import('../views/notice/list.vue')
			},
			{
				path: 'index',
				component: () => import('../views/notice/index.vue')
			}
		]
	},
	{
		path: '/daily',
		name: 'Daily',
		component: Layout,
		redirect: 'daily/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/daily/index.vue')
			}
		]
	},
	{
		path: '/complaint',
		name: 'Complaint',
		component: Layout,
		redirect: 'complaint/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/complaint/index.vue')
			}
		]
	},
	{
		path: '/complaint',
		name: 'Complaint',
		component: Layout,
		redirect: 'complaint/edit',
		children: [
			{
				path: 'edit',
				component: () => import('../views/complaint/edit.vue')
			}
		]
	},
	// 新版考勤
	// {
	// 	path: '/setting',
	// 	name: 'setting',
	// 	component: Layout,
	// 	redirect: 'setting',
	// 	children: [
	// 		{
	// 			path: 'setting',
	// 			component: () => import('../views/attendance/new/setting/index.vue')
	// 		}
	// 	]
	// }
	
	
	
];