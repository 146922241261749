import Layout from '../layout/index.vue'

export default [
	{
		path: '/report',
		name: 'Order',
		component: Layout,
		redirect: 'report/terminal_rate',
		children: [
			{
				path: 'terminal_rate',
				component: () => import('../views/report/terminal_rate.vue')
			},
			{
				path: 'competing_rate',
				component: () => import('../views/report/competing_rate.vue')
			},
			{
				path: 'list',
				component: () => import('../views/report/list.vue')
			},
			{
				path: 'customer_visit',
				component: () => import('../views/report/customer_visit.vue')
			},
			{
				path: 'customer_repeat_visit',
				component: () => import('../views/report/customer_repeat_visit.vue')
			},
			{
				path: 'customer_photo',
				component: () => import('../views/report/customer_photo.vue')
			},
			{
				path: 'freezer',
				component: () => import('../views/report/freezer.vue')
			},
			{
				path: 'release',
				component: () => import('../views/report/release.vue')
			},
			{
				path: 'grant',
				component: () => import('../views/report/grant.vue')
			},
			{
				path: 'setSort',
				component: () => import('../views/report/setSort.vue')
			},
			{
				path: 'customer_level',
				component: () => import('../views/report/customer_level.vue')
			},
			{
			    path:'materiel',
			    component:() =>  import('../views/report/materiel.vue')
			},
			{
			    path:'modify_report',
			    component:() =>  import('../views/report/modify_report.vue')
			},
		]
	},
];