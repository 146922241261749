import req from "../methods/req"
export const getButton = function (data){
    return req({
        url:'/manage/crm.admin/getButton',
        ...data
    })
}

export const getAllButton = function (data) {
	return req({
		url: '/manage/crm.admin/getAllButton',
		...data
	})
}
