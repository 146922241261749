<template>
    <a-locale-provider :locale="locale">
        <div id="app" style="height:100%;">
            <router-view/>
        </div>
  </a-locale-provider>
</template>

<style lang="less">

</style>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import method from './methods/method'
export default {
    data(){
        return {
            locale: zhCN,
        }
    },
    created() {
        let query = method.get_query();
        if (query.rttoken) {
            method.set_local('token', query.rttoken);
        }
    },
}
</script>
