import Layout from '../layout/index.vue'

export default [
	{
		path: '/distributor',
		name: 'Distributor',
		component: Layout,
		redirect: 'distributor/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/distributor/index.vue')
			},
			{
				path: 'details',
				component: () => import('../views/distributor/details.vue')
			},
			{
				path: 'template',
				component: () => import('../views/distributor/template.vue')
			},
			{
				path: 'list',
				component: () => import('../views/distributor/list.vue')
			},
            {
            	path: 'mail-merge',
            	component: () => import('../views/distributor/mail-merge.vue')
            },
          
			
		]
	},
	
	// {
	// 	path: '/stock',
	// 	name: 'Stock',
	// 	component: Layout,
	// 	redirect: 'stock/index',
	// 	children: [
	// 		{
	// 			path: 'index',
	// 			component: () => import('../views/stock/index.vue')
	// 		},
	// 		{
	// 			path: 'compete',
	// 			component: () => import('../views/stock/compete.vue')
	// 		},
	// 	]
	// },
	
];