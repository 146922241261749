import Layout from '../layout/index.vue'

export default [
	{
		path: '/',
		name: 'AgreemenList',
		component: Layout,
		redirect: '/agreemenList',
		children: [
			{
				path: '/agreemenList',
				component: () => import('../views/enterprise/agreemenList.vue'),
				meta: {
					title: '合同公司'
				},
			},
			{
				path: '/mailInfo',
				component: () => import('../views/enterprise/mailInfo.vue'),
				meta: {
					title: '通讯录设置'
				},
			},
			{
				path: '/FieldSettings',
				component: () => import('../views/enterprise/FieldSettings.vue'),
				meta: {
					title: '通讯录可展示字段'
				},
			},
			{
				path: '/Personneletting',
				component: () => import('../views/enterprise/Personneletting.vue'),
				meta: {
					title: '人事档案'
				},
			},
			
			{
				path: '/Personneletting/change',
				component: () => import('../views/enterprise/change.vue'),
				// meta: {
				// 	title: '人事异动'
				// },
				
			},
			{
				path: '/Personneletting/contract',
				component: () => import('../views/enterprise/contract.vue'),
				// meta: {
				// 	title: '合同管理'
				// },
				
			},
			{
				path: '/Personneletting/country',
				component: () => import('../views/enterprise/country.vue'),
				// meta: {
				// 	title: '国家管理'
				// },
				
			},
			{
				path: '/Personneletting/education',
				component: () => import('../views/enterprise/education.vue'),
				// meta: {
				// 	title: '教育经历'
				// },
			},
			
			{
				path: '/Personneletting/id_card',
				component: () => import('../views/enterprise/id_card.vue'),
				// meta: {
				// 	title: '身份证'
				// },
			},
			{
				path: '/Personneletting/nature',
				component: () => import('../views/enterprise/nature.vue'),
				// meta: {
				// 	title: '员工状态'
				// },
			},
			{
				path: '/Personneletting/leave',
				component: () => import('../views/enterprise/leave.vue'),
				// meta: {
				// 	title: '请假设置'
				// },
			},
			{
				path: '/Personneletting/work_hours',
				component: () => import('../views/enterprise/work_hours.vue'),
				// meta: {
				// 	title: '工时设置'
				// },
			},
			{
				path: '/Personneletting/rewards',
				component: () => import('../views/enterprise/rewards.vue'),
				// meta: {
				// 	title: '奖惩设置'
				// },
			},
			{
				path: '/Personneletting/certificate',
				component: () => import('../views/enterprise/certificate.vue'),
				
			},
			{
				path: '/PersonnelRules',
				component: () => import('../views/enterprise/PersonnelRules.vue'),
				meta: {
					title: '人事规则'
				},
			},
			{
				path: '/SpecialPersonneletting',
				component: () => import('../views/enterprise/SpecialPersonneletting.vue'),
				meta: {
					title: '特殊人员设置'
				},
			},
			
			{
				path: '/PersonnelRules/addUserRule',
				component: () => import('../views/enterprise/addUserRule.vue'),
			},
			{
				path: '/PersonnelRules/NoneCodeUserList',
				component: () => import('../views/enterprise/NoneCodeUserList.vue'),
			},
			{
				path: '/PersonnelRules/NoneCodeUserEdit',
				component: () => import('../views/enterprise/NoneCodeUserEdit.vue'),
			},
			{
				path: '/PersonnelTableStyle',
				component: () => import('../views/enterprise/PersonnelTableStyle.vue'),
				meta: {
					title: '人事表格'
				},
			},
			{
				path: '/PersonnelTableStyle/header',
				component: () => import('../views/enterprise/header.vue'),
			},
		]
	},
];