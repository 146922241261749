import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index/index.vue'
import Layout from '../layout/index.vue'
import order from './order'
import approval from './approval'
import operate from './operate'
import user from './user'
import config from './config'
import goods from './goods'
import customer from './customer'
import help from './help'
import attendance from './attendance'
import report from './report'
import bi from './bi'
import cost from './cost'
import distributor from './distributor'
import electronic from './electronic'
import enterprise from './enterprise'
import organization from './organization'
import traceability from './traceability'
import supervision from './supervision'
import freezer from './freezer'

import qms from './qms'


import evection from './evection'
import { getButton, getAllButton } from '@/api/admin'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Index',
		component: Layout,
		redirect: '/user/index',
		children: [
			{
				path: 'index',
				component: Index
			}
		]
	},
	{
		path: '/staging',
		name: 'Staging',
		component: Layout,
		redirect: '/staging/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/staging/index.vue')
			}
		]
	},

	//  考勤相关 打卡配置 出差 日报等等
	...attendance,

	// 客户相关 客户管理 线路管理 拜访管理等等
	...customer,

	// 配置、系统相关
	...config,

	// 商品相关 商品列表
	...goods,

	// 用户相关 登录 人事管理等等
	...user,

	// 运营相关 登录日志 等等
	...operate,

	// 订单
	...order,

	// 审批
	...approval,

	// 帮助
	...help,

	// 报表
	...report,

	// BI 报表
	...bi,

	...cost,
	
	// 差旅相关
	...evection,
	...distributor,

	//电子签收
	...electronic,
	// 企业设置模块相关
	...enterprise,
	//假期
	// 组织
	...organization,

	// 组织
	...qms,
	//溯源码
	...traceability,
	//下沉一线
	...supervision,
	//冰柜管理
	...freezer
	
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


router.beforeEach((to, from, next) => {
	store.commit('set', {
		page_auth: {},
		page_query:{
			...to.query,
		}
	})
	next();
})
router.afterEach((to, from) => {
	let path = to.path;
	let app_auth = store.state.app_auth;
	if (app_auth == null) {
		if(to.path != '/login'){
			getAllButton({
				data: {
					route: to.path,
					fullPath: to.fullPath
				}
			}).then(res => {
				store.commit('set', {
					app_auth: res.data
				})
				if (res.data[path]) {
					store.commit('set', {
						page_auth: res.data[path]
					})
				}
			})
		}
	} else {
		if (app_auth[path]) {
			store.commit('set', {
				page_auth: app_auth[path]
			})
		}
	}

})

// getButton

export default router
