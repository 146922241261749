<template>
    <a-upload
        :showUploadList="false"
        @change="import_upload_change($event,url)"
        :customRequest="this.$method.import_upload"
    >
        <a-button class="a_btn">{{text}}</a-button>
    </a-upload>
</template>
<script>
export default {
	props:{
		url:{
			type:String,
			default:''
		},
		text:{
			type:String,
			default:'导入'
		}
	},
    methods: {
        import_upload_change({ file }, url) {
            if (file.status == 'done') {
                this.$req({
                    url,
                    data: {
                        dir: file.response.filename
                    },
                    info: true,
                });
            }

        }
    }
}
</script>