import Layout from '../layout/index.vue'

export default [
	{
		path: '/freezer',
		name: 'template',
		component: Layout,
		redirect: 'template',
		children: [
			{
				path: 'template',
				component: () => import('../views/freezer/template.vue')
			},
			{
				path: 'template_edit',
				component: () => import('../views/freezer/template/edit.vue')
			},
			{
				path: 'contract',
				component: () => import('../views/freezer/contract.vue')
			},
			{
				path: 'approvalList',
				component: () => import('../views/freezer/approvalList.vue')
			},
			{
				path: 'contract_detail',
				component: () => import('../views/freezer/contract/detail.vue')
			},
			
		]
	},
];

