import Layout from '../layout/index.vue'

export default [
	{
        path: '/approval',
        name:'Approval',
        component: Layout,
        redirect: 'approval/leave',
        children:[
            {
                path:'leave',
                component:() =>  import('../views/approval/leave.vue')
			},
			{
                path:'cost',
                component:() =>  import('../views/approval/cost.vue')
            },
			{
                path:'investment',
                component:() =>  import('../views/approval/investment.vue')
            },
			{
                path:'quit',
                component:() =>  import('../views/approval/quit.vue')
            },
			{
                path:'investment_type',
                component:() =>  import('../views/approval/investment_type.vue')
            },
			{
			    path:'grant',
			    component:() =>  import('../views/approval/grant.vue')
			},
			{
                path:'process',
                component:() =>  import('../views/approval/process.vue')
            },
			{
                path:'process_edit',
                component:() =>  import('../views/approval/process/edit.vue')
            },
        ]
	},
	
];