import Layout from '../layout/index.vue'

export default [
	{
		path: '/config',
		name: 'Config',
		component: Layout,
		redirect: 'config/info',
		children: [
			{
				path: 'info',
				component: () => import('../views/config/info.vue')
			}
		]
	},
	{
		path: '/app_function_menu',
		name: 'App_function_menu',
		component: Layout,
		redirect: 'app_function_menu/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/app_function_menu/index.vue')
			}
		]
	},
	{
		path: '/app_menu',
		name: 'App_menu',
		component: Layout,
		redirect: 'app_menu/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/app_menu/index.vue')
			}
		]
	},
	{
		path: '/menu',
		name: 'Menu',
		component: Layout,
		redirect: 'menu/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/menu/index.vue')
			}
		]
	},
	{
		path: '/queue',
		name: 'Queue',
		component: Layout,
		redirect: 'queue/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/queue/index.vue')
			}
		]
	},

	{
		path: '/auth',
		name: 'Auth',
		component: Layout,
		redirect: 'auth/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/auth/index.vue')
			},
			{
				meta: {
					select_path: 'auth/index'
				},
				path: 'index_edit',
				component: () => import('../views/auth/index/edit.vue')
			}
		]
	},
];