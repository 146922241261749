<template>
    <span>
        <a-tag
            :color="value == 0 ? config.color.error : config.color.primary"
            v-for="item in list"
            :key="item.key"
            v-show="item.key == value"
        >{{item.value}}</a-tag>
    </span>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default() {
                return [...this.$config.status_list]
            }
        },
        value: {
            type: Number,
            default: 1,
        }
    },
    data() {
        return {
            config: this.$config,
        }
    }
}
</script>