import Layout from '../layout/index.vue'

export default [
	{
		path: '/evection',
		name: 'Evection',
		component: Layout,
		redirect: 'evection/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/evection/index.vue')
			},
			{
				path: 'travel_quota',
				component: () => import('../views/evection/travel_quota.vue')
			},
			{
				path: 'travel_standards',
				component: () => import('../views/evection/travel_standards.vue')
			},
			{
				path: 'standards_edit',
				component: () => import('../views/evection/standards_edit.vue')
			},
			{
				path: 'trip',
				component: () => import('../views/evection/trip.vue')
			},
			{
				path: 'trip_details',
				component: () => import('../views/evection/trip/trip_details.vue')
			},
			{
				path: 'travel_approval',
				component: () => import('../views/evection/travel_approval.vue')
			},
			{
				path: 'hotel',
				component: () => import('../views/evection/hotel.vue')
			},
			{
				path: 'hotel_details',
				component: () => import('../views/evection/hotel/hotel_details.vue')
			},
			{
				path: 'recharge',
				component: () => import('../views/evection/recharge.vue')
			},
			{
				path: 'order',
				component: () => import('../views/evection/order.vue')
			},
			{
				path: 'order_details',
				component: () => import('../views/evection/order/order_details.vue')
			},
			{
				path: 'order_export',
				component: () => import('../views/evection/order/export.vue')
			},
			{
				path: 'hotel_clock',
				component: () => import('../views/evection/hotel_clock.vue')
			},
			{
				path: 'hotel_clock_details',
				component: () => import('../views/evection/hotel_clock/hotel_clock_details.vue')
			},
			{
				path: 'report',
				component: () => import('../views/evection/report.vue')
			},
			{
				path: 'subsidy',
				component: () => import('../views/evection/subsidy.vue')
			},
			{
				path: 'subsidy_details',
				component: () => import('../views/evection/subsidy/subsidy_details.vue')
			},
            {
            	path: 'clock_setting',
            	component: () => import('../views/evection/clock_setting')
            },
            // 免签批
            {
            	path: 'visa_exemption',
            	component: () => import('../views/evection/visa_exemption.vue')
            },
            //行程打卡
            {
            	path: 'trip_clock',
            	component: () => import('../views/evection/trip_clock.vue')
            },
            // 行程打卡详情
            {
            	path: 'trip_clock_details',
            	component: () => import('../views/evection/trip/clock_details.vue')
            },
           
		]
	},
	
];