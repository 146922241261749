import Layout from '../layout/index.vue'

export default [
	{
		path: '/help',
		name: 'Help',
		component: Layout,
		redirect: 'help/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/help/index.vue')
			}
		]
	},
	{
		path: '/help_cate',
		name: 'Help_cate',
		component: Layout,
		redirect: 'help_cate/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/help_cate/index.vue')
			}
		]
	},
];