import config from './config'
import store from '../store'
import method from './method'


// 导出过滤器
export default {
	export_form:function(value){
		
			
			return config.host + value + '?token='+ method.get_local('token') + '&' + method.to_url(store.state.table_list_seo_data);
	},
	export_form_seo:function(value,value2){
		if(value2){
			return config.host + value + '?token='+ method.get_local('token') + '&' + method.to_url(store.state.table_list_seo_data)+'&'+value2
		}else{
			return config.host + value + '?token='+ method.get_local('token') + '&' + method.to_url(store.state.table_list_seo_data);
		}
	},

}