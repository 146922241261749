import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.css';

import router from './router'
import store from './store'

import method from './methods/method'
import req from './methods/req'
import config from './methods/config'
import filter from './methods/filter'
import directive from './methods/directive'
import keep_route from './methods/keep_route.js'

import BaiduMap from 'vue-baidu-map'
import echarts from 'echarts'
import ImportUpload from './components/ImportUpload'
import ExportTable from './components/ExportTable'
import Status from './components/Status'
import moment from 'moment'

import './assets/less/app.less'

Vue.config.productionTip = false

Vue.use(Antd);

Vue.use(BaiduMap, {
	ak: '2Fk0GPWh97yNh5zx6b0ZxT8rUK2b9LQt'
});


Vue.prototype.$method = method;
Vue.prototype.$req = req;
Vue.prototype.$echarts = echarts
Vue.prototype.$config = config;
Vue.prototype.$moment = moment;
Vue.prototype.$keep_route = keep_route;

for(let key in filter){
	Vue.filter(key,filter[key]);
}

for(let key in directive){
	Vue.directive(key,directive[key]);
}


Vue.component('import-upload',ImportUpload);
Vue.component('export-table',ExportTable);
Vue.component('status',Status);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
