import Layout from '../layout/index.vue'

export default [

	{
		path: '/operate',
		name: 'Operate',
		component: Layout,
		redirect: 'operate/ads',
		children: [
			{
				path: 'ads',
				component: () => import('../views/operate/ads.vue')
			},
			{
				path: 'phone',
				component: () => import('../views/operate/phone.vue')
			}
		]
	},
	{
		path: '/locus',
		name: 'Locus',
		component: Layout,
		redirect: 'locus/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/locus/index.vue')
			},
			{
				path: 'list',
				component: () => import('../views/locus/list.vue')
			},
		]
	},
	{
		path: '/log',
		name: 'Log',
		component: Layout,
		redirect: 'log/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/log/index.vue')
			}
		]
	},
	{
		path: '/pclog',
		name: 'Log',
		component: Layout,
		redirect: 'log/indexPc',
		children: [
			{
				path: 'index',
				component: () => import('../views/log/indexPc.vue')
			}
		]
	},
	{
		path: '/oplog',
		name: 'Oplog',
		component: Layout,
		redirect: 'oplog/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/oplog/index.vue')
			},
            {
            	path: 'add',
            	component: () => import('../views/oplog/add.vue')
            }
		]
	},

];